body {
  margin: 0;
  font-family:'Kanit', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    background-image: url('https://xn--12csa3dk0bire5d9cicv6ezn.com/assets/etc/bg_body.jpg');
    background-position: top;
    background-repeat-y: no-repeat;
    background-size: auto;
    font-size: 14px;
    background-attachment: fixed;
}
