.App {
  text-align: center;
  max-width: 1280px;
  /*max-width: 2000px;*/
  margin: auto;
  background-color: white;
  background-image: url('https://xn--12csa3dk0bire5d9cicv6ezn.com/assets/etc/texture.jpg');
  
}
.productImg {
  width: 300px;
  max-width: 100%;
  border: 5px solid #59c8ff;
  border-radius: 10rem;
}
.productList{
  text-align: center;
}
.row{
  width: 100%;
  margin: auto;
  display: inline-flex;
}
.col{
  display: inline-block;
  padding: 10px;
  vertical-align: top;
}
.col-9{
  width: 75%;
}
.col-8{
  width: 66.66%;
}
.col-6{
  width: 50%;
}
.col-4{
  width: 33.33%;
}
.col-3{
  width: 25%;
}
.col-2{
  width: 16.66%;
}
.contentBox1 p, .contentBox2 p, .boxDiv {
  background-color: #ffffff;
  margin: 20px;
  padding: 20px;
  border-radius: 0.5rem;
  border: 2px solid #76d6d3;
  border-style: dotted;
  margin-right: 40px;
  margin-left: 40px;
}
.logoHeader {
  max-width: 100px;
  background-color: white;
  border-radius: 10rem;
  padding: 10px;
  margin: 10px;
  border: 2px solid #1cb8ce;
}
header, footer{
  background: linear-gradient(179deg, #272727, #404040);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: ceter;
}
.contentBox1{
  background-image: url('https://xn--12csa3dk0bire5d9cicv6ezn.com/assets/etc/heading_bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 0px;
}
footer{
  background: linear-gradient(167deg, #202020, #414141);
}
.contentBox1, .contentBox2 {
  background-color: #68e3ff26;
  padding-bottom: 20px;
}
.contentBox2 {
  padding-top: 20px;
}
.titleItem{
  font-size: 14px;
}
a {
  text-decoration: none;
  font-weight: 600;
}
.qrcodeImg{
  border: 3px solid #00d3ff;
  width: 90%;
  max-width: 300px;
  margin: 5px;
}
.contactUs{
  background-image: url('https://xn--12csa3dk0bire5d9cicv6ezn.com/assets/etc/ocean_bg_footer.jpg');
  padding: 20px;
  background-repeat: no-repeat;
  background-position: center;
}
.contentBox3{
  background-color: #ffffff;
  margin: auto;
  padding: 20px;
  border-radius: 0.5rem;
  border: 2px solid #76d6d3;
  border-style: dotted;
  display: table;
  background-image: url('https://xn--12csa3dk0bire5d9cicv6ezn.com/assets/etc/texture.jpg');
}

.productImg:hover {
  animation-name: hoverPop;
  animation-duration: .4s;
}

.mobileMenu {
  display: none;
  position: fixed;
  background-color: #2b2b2b;
  z-index: 999;
  width: 100%;
  bottom: 0;
  color: white;
  font-size: 12pt;
  padding: 10px;
  
}
.mobileMenu a{
  text-decoration: none;
  color: white;
  font-weight: 300;
}
img.lineImg {
  vertical-align: text-top;
}
.vdoBox{
  /*min-height: 500px;*/
}
video{
  background-color: white;
}
.reviewImg{
  width: 90%;
  max-width: 500px;
  margin: 10px;
  border-radius: 0.5rem;
}
.reviewRealZone {
  max-width: 1125px;
  margin: auto;
}
.reviewReal {
  width: 22.5%;
}
th {
  background-color: #1170ca;
  color: white;
  padding: 5px;
}
.bgHotBox {
  background-color: rgba(235,255,252,0.75);
  border-top: 3px solid #77cdfe;
  border-bottom: 3px solid #77cdfe;
  border-style: dotted;
  border-left: 0px;
  border-right: 0px;
}
th, td {
  border: 1px solid gray;
}

table {
  margin: auto;
  width: 800px;
}
.vdoBox {
  /* min-height: 500px; */
  max-width: 640px;
  margin: auto;
  padding-top: 20px;
}


@keyframes hoverPop {
  0%, 100% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.05);
  }
  66% {
    transform: scale(.90);
  }
}
@media only screen and (max-width: 1200px) {
  table {
    width: 100% !important;
  }
}
@media only screen and (max-width: 1050px) {
  .col-2{
    width: 20%;
  }
}
@media only screen and (max-width: 900px) {
  .col-2{
    width: 30%;
  }
  iframe{
    width: 90% !important;
    height: 350px !important;
  }
  .vdoBox {
    /*min-height: 350px;*/
  }
  .logoHeader {
    max-width: 50px;
    margin: 0px;
  }
  header{
    padding-bottom: 5px !important;
  }
  .reviewReal {
    width: 45% !important;
  }
}
@media only screen and (max-width: 600px) {
  .col-4, .col-8{
    width: 50%;
  }
  .col-2{
    width: 40%;
  }
  .col-3{
    width: 40%;
  }
  h1{font-size: 18px;}
  h2{font-size: 17px;}
  h3{font-size: 16px;}
  .mobileMenu{
    display: block !important;
    padding-right: 0px;
    padding-left: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  footer{
    margin-bottom: 60px;
  }
  .vdoBox iframe{
    height: 300px !important;
  }
}

@media only screen and (max-width: 350px) {
  
  .col-3{
    width: 40%;
  }
  .col-2{
    width: 40%;
  }
}

@media only screen and (max-width: 280px) {
  
  .col-3{
    width: 80%;
  }
  .col-2{
    width: 80%;
  }
}